import { FC, useRef } from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import { useDraggable } from 'react-use-draggable-scroll';
import { languageSelector } from '~/src/redux/selectors/settings';
import { PromoItemCard } from '~/src/components/homepage/promoBlocks/PromoItemCard';
import { Language, routes } from '~/src/utils/routing';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import { trackBannerLink } from '~/src/utils/dataLayer';

export const PromoBlocks: FC = () => {
  const language = useSelector(languageSelector);
  const ref = useRef();
  const { events } = useDraggable(ref);

  return (
    <PromoCardsContainer {...events} ref={ref}>
      <Cards language={language} />
    </PromoCardsContainer>
  );
};

type PromoItemCardHOCProps = Parameters<typeof PromoItemCard>[0];
const PromoItemCardHOC: FC<PromoItemCardHOCProps> = (props) => (
  <PromoItemCard
    {...props}
    onClick={(e) => trackBannerLink({ link_url: e.currentTarget.href, text: e.currentTarget.innerText })}
  />
);

const Cards: FC<{ language: Language }> = ({ language }) => {
  switch (language) {
    case Language.cs:
      return (
        <FlexBox>
          <PromoItemCardHOC
            text="3+1 den ZDARMA"
            category="BABÍ LÉTO"
            iconSource="sun"
            imageSource="3plus1"
            linksTo={routes.dynamicSegmentNew}
            linkParams={{
              slug: 'babi-leto',
              id: 733,
            }}
            CTAText="zjistit více"
          />
          <PromoItemCardHOC
            text="Darujte zážitek v karavanu"
            category="Dárkové vouchery"
            iconSource="present"
            imageSource="christmas-voucher-2"
            linksTo={routes.voucher}
            CTAText="zjistit více"
          />
          <PromoItemCardHOC
            text="Objevujte krásy světa, z obytňáku"
            category="roadtripy"
            iconSource="road-point"
            imageSource="roadtrip"
            linksTo={routes.campiriRoadtrips}
            CTAText="zjistit více"
          />
          {/* <PromoItemCardHOC */}
          {/*   text="Škola karavaningu" */}
          {/*   category="PŮJČOVÁNÍ BEZ OBAV" */}
          {/*   iconSource="rv-school" */}
          {/*   imageSource="rv-school" */}
          {/*   externalLink="https://www.campiri.com/cs-cz/skola-karavaningu--clanek-405" */}
          {/*   CTAText="zjistit více" */}
          {/* /> */}
        </FlexBox>
      );

    case Language.pl:
      return (
        <FlexBox>
          <PromoItemCardHOC
            text="Wynajmij kampera w Maladze "
            category="Hiszpania"
            iconSource="sun"
            imageSource="spain"
            linksTo={routes.campiriSpain}
            CTAText="Sprawdź ofertę"
          />
          <PromoItemCardHOC
            text="Sprawdź jak zacząć!"
            category="Pierwszy raz w kamperze"
            iconSource="road-point"
            imageSource="first-time"
            linksTo={routes.firstTimeCampervaningLanding}
            CTAText="Czytaj więcej"
          />
          <PromoItemCardHOC
            text="Zacznij zarabiać na swoim kamperze"
            category="Dodaj kampera"
            iconSource="zloty"
            imageSource="camper"
            linksTo={routes.listingWizardLanding}
            CTAText="Sprawdź"
          />
        </FlexBox>
      );

    default:
      return null;
  }
};

const PromoCardsContainer = styled.div`
  display: flex;
  overflow-x: auto;
  margin: 30px auto -40px;

  //to separate scrollbar with white space
  padding-bottom: 5px;
  @media ${deviceMaxWidth.tablet} {
    margin: 30px auto -5px;
    gap: 8px;
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  --scrollbarBG: rgba(207, 216, 220, 0.12);
  --thumbBG: rgba(209, 209, 209, 0.84);
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  ::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
    width: 20px;
  }
`;

const FlexBox = styled.div`
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  gap: 32px;
  margin: auto;
  @media ${deviceMaxWidth.tablet} {
    gap: 8px;
  }
`;
